import React from 'react'
import Meal from '../component/Meal';

export default function FoodRecipe() {
  return (
    <div>
      <Meal />
    </div>
  )
}
